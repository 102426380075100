@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Pixellari";
	src: local("Pixellari"),
		url(./assets/fonts/Pixellari.ttf) format("truetype");
}
@font-face {
	font-family: "ModernDOS";
	src: local("MordernDOS"),
		url(./assets/fonts/ModernDOS8x14.ttf) format("truetype");
}

html,
body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}
body {
	position: relative;
}

@layer components {
	.fa-add-outline path {
		stroke: black;
		stroke-width: 50;
	}

	.backg {
		background-image: url(./assets/images/desktop/sky.jpg);
		/* background-image: url(./assets/images/bg.png); */
		background-size: cover;
	}

	.fast {
		--animate-duration: 0.25s;
	}

	.spin {
		animation: rotation 0.755s linear;
	}

	.doom-scroll {
		animation: doomScroll 2s linear;
	}

	#gradient-canvas {
		width:100%;
		height:100%;
		--gradient-color-1: #e8f3ef;
		--gradient-color-2: #abc0ce;
		--gradient-color-3: #628db1;
		--gradient-color-4: #214777;
		z-index:0;
	  }

	.wish-input-caret {
		&:after {
			content: '';
			display: inline-block;
			width: .75em;
			height: 1.25em;
			background: red;
			animation: cursorblink 1s infinite;
		}
	}


	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(180deg);
		}
	}

	@keyframes doomScroll {
		from {
			transform: translateY(0);
		}
		to {
			transform: translateY(-100%);
		}
	}

	@keyframes cursorblink {
		from {
			opacity: 0
		} to {
			opacity: 1;
		}
	}
}
